<template>
  <div class="app-box">
    <div class="TopBox">
      <div class="left">
        <img src="../../assets/img/operateOper.png" alt="" />
      </div>
      <div class="right">
        <p class="rightText"><span class="spanColor">签约城市:</span>{{listArr.province == null? '全国': listArr.province+listArr.city+listArr.area}}</p>
        <p class="rightText"><span class="spanColor">身份证号:</span>{{listArr.id_card}}</p>
        <p class="rightText_p"><span class="spanColor">{{listArr.truename}}:</span>{{listArr.phone}}</p>
      </div>
      <p class="service">技术服务费 <span class="fr">¥{{listArr.price}}</span></p>
      <p class="serviceText">物料明细</p>
      <div class="titleBoxtxt">
        <p>1、工服：10件</p>
        <p>2、工牌：10个</p>
        <p>3、系统码：40000个</p>
      </div>
    </div>
    <!-- end去支付 -->
    <div class="positFlex">
        <div class="liFlex">
            <p>合计:<span class="colornkae">¥{{listArr.price}}</span></p>
        </div>
        <div class="liFlex1" @click="nextClick">
            去支付
        </div>
    </div>

  </div>
</template>

<script>
import { getOrdersInfo } from "@/request/api";
import { Toast } from 'vant';
export default {
  data() {
    return {
      listArr:[]
    };
  },
  mounted(){
    this.getorderInfo();
  },
  methods:{
    getorderInfo(){
      let data ={
        id:this.$route.query.id
      }
      getOrdersInfo(data).then(res=>{
        if(res.code !=1000){
          Toast.fail(res.message)
          return
        }
        this.listArr = res.data
      })
    },
      nextClick(){
        // this.$router.push('/Voucher');
        this.$router.push({path:'/voucherOper',query:{id:this.$route.query.id}});
      }
  }
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
  overflow: hidden;
}
.TopBox {
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
}
.TopBox .left {
  float: left;
}
.TopBox .right {
  margin-left: 2.3rem;
  font-size: 0.4rem;
  color: #000A33FF;
}
.TopBox .left img {
  width: 2rem;
}
.right .spanColor {
  color: #000A33FF;
  margin-right: .2rem;
}
.TopBox .service {
  font-size: 0.43rem;
  color: #000A33FF;
  line-height: 1rem;
  border-bottom: 1px solid #f3f3f5ff;
  margin-top: .2rem;
}
.fr {
  float: right;
  color: #fb4a51ff;
}
.serviceText {
  font-size: 0.43rem;
  color: #000A33FF;
  margin-top: .3rem;
}
.titleBoxtxt{
    font-size: .4rem;
    margin-top: .2rem;
}
.positFlex{
    width: 100%;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    line-height: 1.5rem;
    box-sizing: border-box;
}
.positFlex .liFlex{
    flex: 4;
    font-size: .45rem;
}
.positFlex .liFlex p{
    padding: 0 .3rem;
}
.positFlex .liFlex1{
    flex: 2;
    text-align: center;
    font-size: .45rem;
    background: #0E8AFFFF;
    color: #fff;
}
.colornkae{
    color: #FB4A51FF;
}
.rightText {
  margin-bottom: 0.1rem;
}
.rightText_p {
  margin-top: 0.3rem;
}
</style>